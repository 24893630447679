import axios from "axios";
import { linkUrl } from "../linkUrl";


const priceDetail = async () => {
    const res = await axios.get(`${linkUrl}/price-detail`);
    if (res.data.status === 200) {
        return res.data.data[0]
    }
}
const priceDetailService = {
    priceDetail
}
export default priceDetailService;