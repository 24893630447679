import axios from "axios";
import { linkUrl } from "../linkUrl";


const sericeData = async () => {
    const res = await axios.get(`${linkUrl}/services`);
    if (res.data.status === 200) {
        return res.data.data
    }
}
const sericeDataService = {
    sericeData
}
export default sericeDataService;