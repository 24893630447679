
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HeroBarComponent from '../component/common/HeroBarComponent'
import LoadingBox from '../component/common/LoadingBox'
import MessageBox from '../component/common/MessageBox'
import { getAboutUs } from '../redux/about/aboutSlice'
import { imgUrl } from '../redux/linkUrl'

// import { API } from '../config'

const AboutUsPage = () => {



    const dispatch = useDispatch();
    const { about: aboutus, isError, isLoading } = useSelector((state) => state.aboutUs);

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            dispatch(getAboutUs())
        })


    }, [])



    return (
        <>

            <HeroBarComponent headingText="About Us" linkRoute="/about-us" linkText="About-us" />

            {isLoading ? (
                <LoadingBox />

            ) : isError ? (
                <MessageBox varient="danger">{isError}</MessageBox>
            ) : (
                <>

                    <div className="container-fluid py-5 wow fadeInUp mb-5" data-wow-delay="0.1s">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-lg-7">
                                    <div className="section-title mb-4">
                                        <h5 className="position-relative d-inline-block text-primary text-uppercase">About Us</h5>
                                        <h1 className="display-5 mb-0">{aboutus.about_title ? aboutus.about_title : ""}</h1>
                                    </div>

                                    {
                                        aboutus.about_des &&
                                        <>
                                            <span className="mb-4" dangerouslySetInnerHTML={{ __html: aboutus.about_des }} >

                                            </span>


                                        </>

                                    }

                                </div>
                                <div className="col-lg-5" style={{ minHeight: "500px" }}>
                                    <div className="position-relative h-100">
                                        <img className="position-absolute w-100  rounded wow zoomIn" data-wow-delay="0.9s" src={aboutus.about_img ? `${imgUrl}${aboutus.about_img}` : ""} style={{ objectfit: "cover" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )

            }




        </>
    )
}

export default AboutUsPage