import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSiteSetting } from '../../redux/setting/settingSlice';
import LoadingBox from './LoadingBox';



const TopHeaderComponent = ({ setting }) => {



    const dispatch = useDispatch();
    const { isLoading, isError, siteSetting } = useSelector((state) => state.siteSetting);

    useEffect(() => {
        dispatch(getSiteSetting())
    }, [dispatch])

    if (isLoading) {
        <p></p>
    }

    return (
        <>
            <div className="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            <small className="py-2"><i className="far fa-clock text-primary me-2"></i>Opening Hours: Sun - Fri : 9.00 am - 6.00 pm, Saturday : 9.00 am - 2.00 pm </small>
                        </div>
                    </div>
                    <div className="col-md-6 text-center text-lg-end">
                        <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
                            <div className="me-3 pe-3 border-end py-2">
                                {
                                    siteSetting && siteSetting.site_email &&
                                    <a href={`mailto:${siteSetting.site_email ? siteSetting.site_email : ""}`} style={{ color: "white" }}>
                                        <p className="m-0"><i className="fa fa-envelope-open me-2 pr-3"></i>{siteSetting.site_email ? siteSetting.site_email : ""}</p>
                                    </a>


                                }
                            </div>
                            <div className="py-2">
                                {
                                    siteSetting && siteSetting.site_phone &&

                                    <a href={`tel:${siteSetting.site_phone ? siteSetting.site_phone : ""}`} style={{ color: "white" }}>
                                        <p className="m-0"><i className="fa fa-phone-alt me-2"></i>{siteSetting.site_phone ? siteSetting.site_phone : ""}</p>
                                    </a>


                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopHeaderComponent