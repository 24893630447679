
import React, { useEffect, useState } from 'react'
import ImageSlider from 'react-image-comparison-slider'
import { useDispatch, useSelector } from 'react-redux'

import HeroBarComponent from '../component/common/HeroBarComponent'
import LoadingBox from '../component/common/LoadingBox'
import MessageBox from '../component/common/MessageBox'


import { imgUrl } from '../redux/linkUrl'
import { getServices } from '../redux/service/serviceDataSlice'
import { getSiteSetting } from '../redux/setting/settingSlice'

const ServicePage = () => {



    const dispatch = useDispatch();
    const { isLoading, isError, services } = useSelector((state) => state.services);

    const { siteSetting: setting } = useSelector((state) => state.siteSetting);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getServices())
        dispatch(getSiteSetting())



    }, [dispatch])










    return (
        <>

            <HeroBarComponent headingText="Services" linkRoute="/services" linkText="Services" />







            <div className="container-fluid py-5 wow fadeInUp mb-5" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-lg-5 wow zoomIn position-relative  overflow-hidden" data-wow-delay="0.3s" style={{ minHeight: "400px" }}>
                            <div className=" position-relative h-100 rounded overflow-hidden">
                                <ImageSlider

                                    image1={"/assets/img/before.jpg"}
                                    image2={"/assets/img/after.jpg"}
                                    sliderWidth={3}
                                    sliderColor="white"
                                    handleColor="red"
                                    handleBackgroundColor="white"
                                    className="img-fluid position-absolute w-100 h-100"
                                    style={{ objectFit: 'cover' }}
                                />

                            </div>





                        </div>
                        <div className="col-lg-7">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Services</h5>
                                <h1 className="display-5 mb-0">We Offer The Best Quality Dental Services</h1>
                            </div>
                            <div className="row g-5">

                                {isLoading ? (
                                    <LoadingBox />
                                ) : isError ? (
                                    <MessageBox varient="danger">{isError}</MessageBox>
                                ) : (

                                    <>
                                        {
                                            services && services.length > 0 && services.slice(0, 2).map((service, idx) => (
                                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s" key={idx}>
                                                    <div className="rounded-top overflow-hidden">
                                                        <img className="img-fluid" src={`${imgUrl}${service.service_img}`} alt="service image" />
                                                    </div>
                                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                                        <h5 className="m-0">{service.service_name}</h5>
                                                    </div>
                                                </div>
                                            ))

                                        }

                                    </>
                                )

                                }
                            </div>





                        </div>
                    </div>
                    <div className="row g-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-12">
                            <div className="row g-5">
                                {isLoading ? (
                                    <>..</>
                                ) : (
                                    <>
                                        {
                                            services && services.length > 2 && services.slice(2).map((service, idx) => (
                                                <div className="col-md-6 col-lg-3 service-item wow zoomIn" data-wow-delay="0.6s" key={idx}>
                                                    <div className="rounded-top overflow-hidden">
                                                        <img className="img-fluid" src={`${imgUrl}${service.service_img}`} alt="service image" />
                                                    </div>
                                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                                        <h5 className="m-0">{service.service_name}</h5>
                                                    </div>
                                                </div>
                                            ))

                                        }
                                    </>
                                )

                                }

                            </div>
                        </div>

                    </div>

                    <div className="col-lg-5 service-item wow zoomIn mt-5" data-wow-delay="0.9s">
                        <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
                            <h3 className="text-white mb-3">Make Appointment</h3>
                            <h5 className="text-white">Contact Us For Appointment </h5>
                            <h2 className="text-white mb-0">{setting && setting.site_phone ? setting.site_phone : ""}</h2>
                        </div>
                    </div>
                </div>
            </div>




        </>




    )
}

export default ServicePage