
// import { siteSettingsReducer } from "../reducers/siteSettingReducers";
// import { sliderReducer } from "../reducers/sliderReducers"
// import { aboutReducer } from "../reducers/aboutReducers"
import { configureStore } from "@reduxjs/toolkit";
import bannerReducer from "./slider/bannerSlice";
import siteSettingsReducer from "./setting/settingSlice";
import aboutReducer from "./about/aboutSlice";
import offerReducer from "./offer/offerSlice"
import servicesReducer from "./service/serviceDataSlice"
import priceDetailReducer from "./priceSectionDetail/priceDetailSlice"
import dentistDetailReducer from "./dentist/dentistSlice"







// const reducer = combineReducers({
//     siteSetting: siteSettingsReducer,
//     slider: sliderReducer,
//     aboutUs: aboutReducer

// })

// const middleware = [thunk];

export const store = configureStore({
    reducer: {
        banners: bannerReducer,
        siteSetting: siteSettingsReducer,
        aboutUs: aboutReducer,
        offer: offerReducer,
        services: servicesReducer,
        priceDetail: priceDetailReducer,
        dentistDetail: dentistDetailReducer

    },

}

);
export default store;