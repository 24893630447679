import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import { imgUrl } from '../../redux/linkUrl';
import { getSiteSetting } from '../../redux/setting/settingSlice';



const NavBarComponent = () => {
    const dispatch = useDispatch();
    const { isLoading, isError, siteSetting } = useSelector((state) => state.siteSetting);

    useEffect(() => {

        dispatch(getSiteSetting())


    }, [dispatch])
    //  for burger munu

    const handleCollapse = () => {

        var nav = document.getElementById("navbarCollapse");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
    };


    // if (isLoading) {
    //     return <p></p>
    // }

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
                <NavLink to="/" className="navbar-brand p-4">
                    <img className="img-fluid" style={{ maxHeight: "50px" }} src={siteSetting && siteSetting.site_logo && siteSetting.site_logo !== null ? `${imgUrl}${siteSetting.site_logo}` : ""} />

                    <span className='wow fadeInUp d-none d-sm-block' style={{ color: ' #00b3f0' }}>{siteSetting && siteSetting.site_title ? siteSetting.site_title : ""}</span>
                    {/* <h1 className="m-0 text-primary"><i className="fa fa-tooth me-2"></i>DentCare</h1> */}
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" id="navbarBtn">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">


                        <NavLink to="/" className={({ isActive }) => 'nav-item nav-link' + (isActive ? ' active' : '')} onClick={handleCollapse}>Home</NavLink>

                        <NavLink to="/about-us" className={({ isActive }) => 'nav-item nav-link' + (isActive ? ' active' : '')} onClick={handleCollapse}>About</NavLink>


                        <NavLink to="/services" className={({ isActive }) => 'nav-item nav-link' + (isActive ? ' active' : '')} onClick={handleCollapse}>Service</NavLink>
                        <NavLink to="/our-team" className={({ isActive }) => 'nav-item nav-link' + (isActive ? ' active' : '')} onClick={handleCollapse}>Team</NavLink>


                        <NavLink to="/contact-us" className={({ isActive }) => 'nav-item nav-link' + (isActive ? ' active' : '')} onClick={handleCollapse}>Contact</NavLink>


                    </div>
                    {/* <button type="button" className="btn text-dark" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search"></i></button> */}
                    <Link to="/appointment" className="btn btn-primary py-2 px-4 ms-3">Appointment</Link>
                </div>
            </nav>

        </>
    )
}

export default NavBarComponent