import React from 'react'

const MessageBox = (props) => {
    return (
        < >

            <div className={`alert alert-${props.variant || 'primary'} m-4`} role="alert">
                <strong>{props.children}</strong>
            </div>

        </ >
    )
}



export default MessageBox