
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import siteSettingService from "./settingService";


const initialState = {
    siteSetting: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
}

export const getSiteSetting = createAsyncThunk("settings", async (thunkAPI) => {
    try {
        return await siteSettingService.siteSetting();
    } catch (err) {
        const message = (
            err.response && err.response.data && err.response.data.message
        ) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const siteSettingSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getSiteSetting.pending, (state) => {
                    state.isLoading = true
                }

            )
            .addCase(
                getSiteSetting.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.siteSetting = action.payload;

                }
            )
            .addCase(
                getSiteSetting.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.message = action.payload;
                    state.siteSetting = null
                }
            )
    }
})

export const { reset } = siteSettingSlice.actions;
export default siteSettingSlice.reducer;