import { MyRoute } from "./MyRoute"

import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicePage from "./pages/ServicePage";
import OurDentisrPage from "./pages/OurDentistPage";
import ContactPage from "./pages/ContactPage";
import AppointmentPage from "./pages/AppointmentPage";
import PageNotFound from "./pages/PageNotFound";




function App() {
  return (
    <Routes>
      <Route path="/" element={<MyRoute />} >
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/our-team" element={<OurDentisrPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/appointment" element={<AppointmentPage />} />
        <Route path="*" element={<PageNotFound />} />





      </Route>
    </Routes>









  );
}

export default App;
