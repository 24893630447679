import React from 'react'
import { Link } from 'react-router-dom'

const HeroBarComponent = ({ headingText, linkRoute, linkText }) => {
    return (
        <>
            <div className="container-fluid bg-primary py-5 hero-header mb-5">
                <div className="row py-3">
                    <div className="col-12 text-center">
                        <h1 className="display-3 text-white animated zoomIn">{headingText}</h1>
                        <Link to="/" className="h4 text-white">Home</Link>
                        <i className="fas fa-slash text-white px-2"></i>
                        {/* <i className="far fa-circle text-white px-2"></i> */}
                        <Link to={linkRoute} className="h4 text-white">{linkText}</Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HeroBarComponent