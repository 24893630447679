import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { Route, BrowserRouter, Routes } from 'react-router-dom'



import store from "./redux/store";
import TopHeaderComponent from './component/common/TopHeaderComponent';
import NavBarComponent from './component/common/NavBarComponent';
import FooterComponent from './component/common/FooterComponent'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>

      <Provider store={store}>
        <TopHeaderComponent />
        <NavBarComponent />
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
        <FooterComponent />
      </Provider>

    </BrowserRouter>
  </React.StrictMode>
);


