
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { getBanners } from '../../redux/slider/bannerSlice';
import LoadingBox from '../common/LoadingBox';


const CarouselComponent = () => {
    const dispatch = useDispatch();
    const { banners, imgurl, isLoading } = useSelector((state) => state.banners);

    useEffect(() => {

        dispatch(getBanners())



    }, [dispatch])

    // if (isLoading) {
    //     return <p>...</p>
    // }


    return (
        <>
            <div className="container-fluid p-0">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-wow-delay="2s">
                    <div className="carousel-inner">
                        {
                            banners && banners.slice(0, 5).map((banner, idx) => (
                                <div className={`carousel-item ${idx == 0 ? 'active' : ""}`} key={idx} >
                                    {banner && banner.slider_img ? <img className="w-100" src={`${imgurl}/${banner.slider_img}`} alt="Image" /> : ""}
                                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                        <div className="p-3" style={{ maxWidth: "900px" }}>

                                            <h1 className="display-1 text-white mb-md-4 animated zoomIn" data-wow-delay="2s">{banner.slider_title}</h1>
                                            <Link to="/appointment" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Appointment</Link>
                                            <Link to="/contact-us" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>


        </>

    )
}

export default CarouselComponent