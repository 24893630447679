import axios from "axios";
import { linkUrl } from "../linkUrl";


const aboutUs = async () => {
    const res = await axios.get(`${linkUrl}/aboutus`);
    if (res.data.status === 200) {
        return res.data.data[0]
    }
}
const aboutUsService = {
    aboutUs
}
export default aboutUsService;