import axios from "axios";
import { linkUrl } from "../linkUrl";


const dentistData = async () => {
    const res = await axios.get(`${linkUrl}/alldoctors`);
    if (res.data.status === 200) {
        return res.data.data
    }
}
const dentistDataService = {
    dentistData
}
export default dentistDataService;