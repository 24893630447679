import axios from "axios";
import { linkUrl } from "../linkUrl";


const siteSetting = async () => {
    const res = await axios.get(`${linkUrl}/sitesetting`);
    if (res.data.status === 200) {
        return res.data.data[0];
    }
}
const siteSettingService = {
    siteSetting
}
export default siteSettingService;