import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getSiteSetting } from '../../redux/setting/settingSlice';
// import { API } from '../../config';
import LoadingBox from './LoadingBox';

const FooterComponent = () => {








    const dispatch = useDispatch();
    const { isLoading, isError, siteSetting: contact } = useSelector((state) => state.siteSetting);

    useEffect(() => {
        dispatch(getSiteSetting())
    }, [dispatch])

    if (isLoading) {
        return <>.</>
    }




    return (

        <>

            <div className="container-fluid bg-dark text-light py-5 wow fadeInUp" data-wow-delay="0.3s" >
                <div className="container pt-5">
                    <div className="row g-5 pt-4">
                        <div className="col-lg-4 col-md-6">
                            <h3 className="text-white mb-4">Quick Links</h3>
                            <div className="d-flex flex-column justify-content-start">
                                <Link className="text-light mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                                <Link className="text-light mb-2" to="/about-us"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                                <Link className="text-light mb-2" to="/services"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</Link>
                                <Link className="text-light" to="/contact-us"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                            </div>
                        </div>
                        {

                            <>
                                {contact &&
                                    <div className="col-lg-4 col-md-6">
                                        <h3 className="text-white mb-4">Get In Touch</h3>
                                        <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2"></i>{contact.site_address ? contact.site_address : ""}</p>
                                        <a href={`mailto:${contact.site_email ? contact.site_email : ""}`} style={{ color: "white" }}>
                                            <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>{contact.site_email ? contact.site_email : ""}</p>

                                        </a>
                                        <a href={`tel:${contact.site_phone ? contact.site_phone : ""}`} style={{ color: "white" }}>
                                            <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>{contact.site_phone ? contact.site_phone : ""}</p>
                                        </a>
                                    </div>}

                            </>





                        }


                        {


                            <>
                                {contact &&

                                    <div className="col-lg-4 col-md-6 ">
                                        <h3 className="text-white mb-4">Follow Us</h3>
                                        <div className="d-flex">

                                            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href={contact.site_fb_link ? contact.site_fb_link : ""} target="_blank"><i className="fab fa-facebook-f fw-normal"></i></a>

                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href={contact.site_in_link ? contact.site_in_link : ""} target="_blank"><i className="fab fa-instagram fw-normal"></i></a>
                                        </div>
                                    </div>
                                }

                            </>


                        }


                    </div>
                </div>
            </div>
            <div className="container-fluid text-light py-4" style={{ background: "#051225" }}>
                <div className="container">
                    <div className="row g-0">
                        <div className="col-md-6 text-center text-md-start">
                            <p className="mb-md-0">&copy; <Link className="text-white border-bottom" to="/">{contact && contact.site_title ? contact.site_title : ""}</Link></p>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <p className="mb-0">Designed by <a className="text-white border-bottom" href="https://www.blackhawk.edu.np/" target="_blank">BlackHawk Team</a><br />

                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default FooterComponent