
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import aboutUsService from "./aboutService";



const initialState = {
    about: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
}

export const getAboutUs = createAsyncThunk("aboutData", async (thunkAPI) => {
    try {
        return await aboutUsService.aboutUs();
    } catch (err) {
        const message = (
            err.response && err.response.data && err.response.data.message
        ) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const aboutUsSlice = createSlice({
    name: "aboutData",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getAboutUs.pending, (state) => {
                    state.isLoading = true
                }

            )
            .addCase(
                getAboutUs.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.about = action.payload;

                }
            )
            .addCase(
                getAboutUs.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.message = action.payload;
                    state.about = null
                }
            )
    }
})

export const { reset } = aboutUsSlice.actions;
export default aboutUsSlice.reducer;