
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import offerService from "./offerService";


const initialState = {
    offer: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",

}

export const getOffer = createAsyncThunk("offers", async (thunkAPI) => {
    try {
        return await offerService.offerData();
    } catch (err) {
        const message = (
            err.response && err.response.data && err.response.data.message
        ) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const offerSlice = createSlice({
    name: "offers",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getOffer.pending, (state) => {
                    state.isLoading = true
                }

            )
            .addCase(
                getOffer.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.offer = action.payload;

                }
            )
            .addCase(
                getOffer.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.message = action.payload;
                    state.offer = null
                }
            )
    }
})

export const { reset } = offerSlice.actions;
export default offerSlice.reducer;