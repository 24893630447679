import axios from "axios";
import { linkUrl } from "../linkUrl";


const banners = async () => {
    const res = await axios.get(`${linkUrl}/slider`);
    if (res.data.status === 200) {
        return res.data.data
    }
}
const bannerService = {
    banners
}
export default bannerService;