
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import priceDetailService from "./priceDetailService";




const initialState = {
    priceDetail: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
}

export const getPriceDetail = createAsyncThunk("priceDetails", async (thunkAPI) => {
    try {
        return await priceDetailService.priceDetail();
    } catch (err) {
        const message = (
            err.response && err.response.data && err.response.data.message
        ) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const priceDetailSlice = createSlice({
    name: "priceDetails",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getPriceDetail.pending, (state) => {
                    state.isLoading = true
                }

            )
            .addCase(
                getPriceDetail.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.priceDetail = action.payload;

                }
            )
            .addCase(
                getPriceDetail.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.message = action.payload;
                    state.priceDetail = null
                }
            )
    }
})

export const { reset } = priceDetailSlice.actions;
export default priceDetailSlice.reducer;