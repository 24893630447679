import axios from 'axios';
import React, { useEffect, useState } from 'react'

import MessageBox from '../common/MessageBox';

import { linkUrl } from '../../redux/linkUrl';

const AppointmentHomeComponent = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [appoDate, setAppoDate] = useState('');
    const [phone, setphone] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [succmesg, setSuccmesg] = useState(null);
    const [appoDetail, setAppoDetail] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        appointment_detail();



    }, [])
    const appointment_detail = async () => {
        try {
            const res = await axios.get(`${linkUrl}/appo-detail`);

            if (res.data.status === 200) {
                setLoading(false);
                setAppoDetail(res.data.data[0]);
                setError(null);
            }

        } catch (err) {
            setError(err.message);
            setAppoDetail(null);
        }
        finally {
            setLoading(false);
        }
    }





    const submitValue = async (e) => {

        e.preventDefault();
        const appointmentForm = {
            fullName,
            email,
            appoDate,
            phone,
            message
        }



        if (appointmentForm !== null) {
            try {
                const res = await axios.post(`${linkUrl}/appointment`, appointmentForm);
                setFullName("");
                setEmail("");
                setAppoDate("");
                setphone("");
                setMessage("");



                if (res.data.status === 200) {
                    setLoading(false);
                    setSuccmesg("We Receive Your Appointment Request")
                    //  setOffers(res.data.data[0]);
                    setError(null);
                }

            } catch (err) {

                setError("Something went worng or Appointment date not found");

                // setOffers(null);
            }
            finally {
                setLoading(false);
            }
        }

        setFullName("");
        setEmail("");
        setAppoDate("");
        setphone("");
        setMessage("");



    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };



    return (
        <>
            <div className="container-fluid bg-primary bg-appointment my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-6 py-5">
                            <div className="py-5">
                                {
                                    appoDetail && appoDetail != null &&
                                    <>
                                        <h3 className="display-5 text-white mb-4">{appoDetail.appointment_title ? appoDetail.appointment_title : ""}</h3>
                                        <p className="text-white mb-0" dangerouslySetInnerHTML={{ __html: appoDetail.appointment_des ? appoDetail.appointment_des.slice(0, 650) : "" }} ></p>

                                    </>
                                }

                            </div>
                        </div>



                        <div className="col-lg-6">




                            {/* justify-content-center */}
                            <div className="appointment-form h-100 d-flex flex-column text-center p-5 wow zoomIn" data-wow-delay="0.6s">

                                {
                                    error ? (
                                        <MessageBox varient="danger">{error}</MessageBox>
                                    ) : ""

                                }

                                <>
                                    {
                                        succmesg ? (
                                            <MessageBox varient="success">{succmesg}</MessageBox>
                                        ) : ""

                                    }
                                </>

                                <h1 className="text-white mb-4">Make Appointment</h1>


                                <form onSubmit={submitValue}>
                                    <div className="row g-3">


                                        <div className="col-12 col-sm-6">
                                            <input type="text" className="form-control bg-light border-0"
                                                placeholder="Your Name"
                                                style={{ height: "55px" }}
                                                name="fullName"
                                                value={fullName}
                                                onChange={e => setFullName(e.target.value)} required />

                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input type="email" className="form-control bg-light border-0"
                                                name="email"
                                                value={email}
                                                placeholder="Your Email" style={{ height: "55px" }} onChange={e => setEmail(e.target.value)} required />
                                        </div>
                                        <div className="col-12 col-sm-6">


                                            <input id="startDate" className="form-control bg-light border-0" name="appoDate"
                                                min={disablePastDate()}
                                                value={appoDate} type="date" style={{ height: "55px" }} onChange={e => setAppoDate(e.target.value)} />



                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="time" id="time1" data-target-input="nearest">
                                                <input type="number"
                                                    className="form-control bg-light border-0"
                                                    name="phone"
                                                    value={phone}

                                                    placeholder="Contact Number" style={{ height: "55px" }} onChange={e => setphone(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="time" id="time1" data-target-input="nearest">
                                                <textarea className="form-control border-0 bg-light px-4 py-3"
                                                    name="message"
                                                    value={message}
                                                    rows="5" placeholder="Message" onChange={e => setMessage(e.target.value)} maxLength={500}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-dark w-100 py-3" type="submit">Make Appointment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AppointmentHomeComponent