import React from "react";
import { Outlet } from "react-router-dom";


export const MyRoute = () => {


    return (
        <>
            {/* <TopHeaderComponent />
            <NavBarComponent /> */}
            <Outlet />
            {/* <FooterComponent /> */}
        </>
    )
}