import axios from "axios";
import { linkUrl } from "../linkUrl";


const offerData = async () => {
    const res = await axios.get(`${linkUrl}/offer`);
    if (res.data.status === 200) {
        return res.data.data[0]
    }
}
const offerService = {
    offerData
}
export default offerService;