import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HeroBarComponent from '../component/common/HeroBarComponent'
import LoadingBox from '../component/common/LoadingBox'
import MessageBox from '../component/common/MessageBox'


import { linkUrl } from '../redux/linkUrl'
import { getSiteSetting } from '../redux/setting/settingSlice'

const ContactPage = () => {
    const [name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    // const [contact, setContact] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [succmesg, setSuccmesg] = useState(null);


    const dispatch = useDispatch();
    const { isLoading, isError, siteSetting: contact } = useSelector((state) => state.siteSetting);



    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getSiteSetting())



    }, [dispatch])



    const submitValue = async (e) => {

        e.preventDefault();
        const contactForm = {
            name,
            email,
            subject,
            message
        }
        if (contactForm !== null) {
            try {
                const res = await axios.post(`${linkUrl}/contactus`, contactForm);
                setFullName("");
                setEmail("");

                setSubject("");
                setMessage("");

                if (res.data.status === 200) {

                    setLoading(false);
                    setSuccmesg("We Receive your message")
                    //  setOffers(res.data.data[0]);
                    setError(null);
                }

            } catch (err) {

                setError("Something went worng ");

                // setOffers(null);
            }
            finally {
                setLoading(false);
            }
        }
        setFullName("");
        setEmail("");

        setSubject("");
        setMessage("");






    }


    return (
        <>

            <HeroBarComponent headingText="Contact Us" linkRoute="/contact-us" linkText="Contact Us" />
            {/* <!-- Contact Start --> */}

            {
                isLoading ? (

                    <>..</>
                ) : (
                    <div className="container-fluid py-5 mb-5">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.1s">
                                    <div className="bg-light rounded h-100 p-5">
                                        <div className="section-title">
                                            <h5 className="position-relative d-inline-block text-primary text-uppercase">Contact Us</h5>
                                            <h1 className="display-6 mb-4">Feel Free To Contact Us</h1>
                                        </div>


                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                                            <div className="text-start">
                                                <h5 className="mb-0">Our Office</h5>
                                                <span>{contact && contact.site_address ? contact.site_address : ""}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
                                            <div className="text-start">
                                                <h5 className="mb-0">Email Us</h5>
                                                <a href={`mailto:${contact && contact.site_email ? contact.site_email : ""}`} style={{ color: '#6B6A75' }}>
                                                    <span>{contact && contact.site_email ? contact.site_email : ""}</span>

                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                                            <div className="text-start">
                                                <h5 className="mb-0">Call Us</h5>
                                                <a href={`tel:${contact && contact.site_phone ? contact.site_phone : ""}`} style={{ color: '#6B6A75' }}>
                                                    <span>{contact && contact.site_phone ? contact.site_phone : ""}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.3s">

                                    {
                                        error ? (
                                            <MessageBox varient="danger">{error}</MessageBox>
                                        ) : ""

                                    }

                                    <>
                                        {
                                            succmesg ? (
                                                <MessageBox varient="success">{succmesg}</MessageBox>
                                            ) : ""

                                        }
                                    </>
                                    <form onSubmit={submitValue}>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <input type="text"
                                                    name="name"
                                                    value={name}
                                                    onChange={e => setFullName(e.target.value)}
                                                    className="form-control border-0 bg-light px-4"
                                                    placeholder="Your Name" style={{ height: "55px" }} required />
                                            </div>
                                            <div className="col-12">
                                                <input type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    className="form-control border-0 bg-light px-4"
                                                    placeholder="Your Email" style={{ height: "55px" }} required />
                                            </div>
                                            <div className="col-12">
                                                <input type="text"
                                                    name="subject"
                                                    value={subject}
                                                    onChange={e => setSubject(e.target.value)}
                                                    className="form-control border-0 bg-light px-4"
                                                    placeholder="Subject" style={{ height: "55px" }} />
                                            </div>
                                            <div className="col-12">
                                                <textarea className="form-control border-0 bg-light px-4 py-3"
                                                    name="message"
                                                    value={message}
                                                    onChange={e => setMessage(e.target.value)}
                                                    maxLength={300}
                                                    rows="5" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-xl-4 col-lg-12 wow slideInUp" data-wow-delay="0.6s">
                                    <iframe className="position-relative rounded w-100 h-100"
                                        src={contact && contact.site_map ? contact.site_map : ""}
                                        frameBorder="0" style={{ minHeight: "400px", border: "0" }} allowFullScreen="" aria-hidden="false"
                                        tabIndex="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


            {/* <!-- Contact End --> */}


        </>
    )
}

export default ContactPage