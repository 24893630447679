
import React, { useEffect } from 'react'
import ImageSlider from "react-image-comparison-slider";
import { useDispatch, useSelector } from 'react-redux';
import { imgUrl } from '../../redux/linkUrl';
import { getServices } from '../../redux/service/serviceDataSlice';
import LoadingBox from '../common/LoadingBox';





const ServiceHomeComponent = ({ setting }) => {

    const dispatch = useDispatch();
    const { isLoading, isError, services } = useSelector((state) => state.services);

    useEffect(() => {

        dispatch(getServices())



    }, [dispatch])




    return (
        <>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">


                    <div className="row g-5 mb-5">
                        <div className="col-lg-5 wow zoomIn" data-wow-delay="0.3s" style={{ minHeight: "477px" }}>
                            <ImageSlider
                                image1={"/assets/img/before.jpg"}
                                image2={"/assets/img/after.jpg"}
                                sliderWidth={3}
                                sliderColor="white"
                                handleColor="red"
                                handleBackgroundColor="white"
                                className="img-fluid"
                            />
                        </div>



                        <div className="col-lg-7">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Services</h5>
                                <h1 className="display-5 mb-0">We Offer The Best Quality Dental Services</h1>
                            </div>

                            <div className="row g-5">
                                {isLoading ? (
                                    <LoadingBox />
                                ) : (
                                    <>
                                        {
                                            services && services.length > 0 && services.slice(0, 2).map((service, idx) => (
                                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s" key={idx}>
                                                    <div className="rounded-top overflow-hidden">
                                                        <img className="img-fluid" src={`${imgUrl}${service.service_img}`} alt="service image" />
                                                    </div>
                                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                                        <h5 className="m-0">{service.service_name}</h5>
                                                    </div>
                                                </div>
                                            ))

                                        }




                                    </>

                                )
                                }

                            </div>

                        </div>

                    </div>
                    <div className="row g-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-7">
                            <div className="row g-5">
                                {isLoading ? (
                                    <LoadingBox />
                                ) : (
                                    <>
                                        {
                                            services && services.length > 2 && services.slice(2, 4).map((service, idx) => (
                                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s" key={idx}>
                                                    <div className="rounded-top overflow-hidden">
                                                        <img className="img-fluid" src={`${imgUrl}${service.service_img}`} alt="service image" />
                                                    </div>
                                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                                        <h5 className="m-0">{service.service_name}</h5>
                                                    </div>
                                                </div>
                                            ))

                                        }
                                    </>
                                )
                                }


                            </div>
                        </div>




                        <div className="col-lg-5 service-item wow zoomIn" data-wow-delay="0.9s">
                            <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
                                <h3 className="text-white mb-3">Make Appointment</h3>
                                <h5 className="text-white">Contact Us For Appointment </h5>
                                <h2 className="text-white mb-0">{setting && setting.site_phone ? setting.site_phone : ""}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ServiceHomeComponent