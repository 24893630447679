import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import { API } from '../../config';

import MessageBox from '../common/MessageBox';
import { useDispatch, useSelector } from 'react-redux'
import { getAboutUs } from '../../redux/about/aboutSlice';
import { imgUrl } from '../../redux/linkUrl';

const ShortAbout = () => {



    const dispatch = useDispatch();
    const { about, isError, isLoading } = useSelector((state) => state.aboutUs);

    useEffect(() => {

        dispatch(getAboutUs())



    }, [])

    return (
        <>

            {isLoading ? (
                // <LoadingBox />
                <p>loading...</p>
            ) : isError ? (
                <MessageBox varient="danger">{isError}</MessageBox>
            ) : (
                <>
                    {
                        about &&
                        <div className="container-fluid pt-5 wow fadeInUp" data-wow-delay="1s">
                            <div className="container">
                                <div className="row g-5">
                                    <div className="col-lg-7">
                                        <div className="section-title mb-4">
                                            <h5 className="position-relative d-inline-block text-primary text-uppercase">About Us</h5>
                                            <h1 className="display-5 mb-0">{about.about_title ? about.about_title : ""}</h1>
                                        </div>
                                        {/* <h4 className="text-body fst-italic mb-4">Diam dolor diam ipsum sit. Clita erat ipsum et lorem stet no lorem sit clita duo justo magna dolore</h4> */}
                                        {
                                            about.about_des &&
                                            <>
                                                <span className="mb-4" dangerouslySetInnerHTML={{ __html: about.about_des.slice(0, 500) + '.......' }} >

                                                </span>


                                            </>
                                        }


                                    </div>
                                    <div className="col-lg-5" style={{ minHeight: "500px" }}>
                                        <div className="position-relative h-100">
                                            <img className="position-absolute w-100  rounded wow zoomIn" data-wow-delay="0.9s" src={about.about_img ? `${imgUrl}${about.about_img}` : ""}
                                                style={{ objectFit: "cover" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }


                </>

            )
            }



        </>
    )
}

export default ShortAbout