import React, { useEffect, useState } from 'react'

import LoadingBox from '../common/LoadingBox';
import MessageBox from '../common/MessageBox';
import { getOffer } from '../../redux/offer/offerSlice';
// import { API } from "../../config"
import { imgUrl } from "../../redux/linkUrl"
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const OfferComponent = () => {




    const dispatch = useDispatch();
    const { isLoading, isError, offer } = useSelector((state) => state.offer);

    useEffect(() => {

        dispatch(getOffer())


    }, [dispatch])



    return (
        <>
            {isLoading ? (
                <LoadingBox />
            ) : isError ? (
                <MessageBox varient="danger">{isError}</MessageBox>
            ) : (
                <>
                    {
                        offer &&
                        <div className="container-fluid my-5 py-5 wow fadeInUp" data-wow-delay="0.1s"
                            style={{ background: `url(${offer && offer.offer_img ? `${imgUrl}${offer.offer_img}` : ""}) center center no-repeat`, backgroundSize: "cover" }}>

                            <div className="container py-5">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 wow zoomIn" data-wow-delay="0.6s">
                                        <div className="offer-text text-center rounded p-5 ">
                                            <h1 className="display-5 text-white">{offer && offer.offer_name ? offer.offer_name : ""}</h1>
                                            {
                                                offer.offer_dec &&
                                                <div className="text-white mb-4" dangerouslySetInnerHTML={{ __html: offer && offer.offer_dec ? offer.offer_dec : "" }} />
                                            }

                                            <Link to="/appointment" className="btn btn-dark py-3 px-5 me-3">Appointment</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }


                </>


            )

            }




        </>

    )
}

export default OfferComponent