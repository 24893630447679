import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import HeroBarComponent from '../component/common/HeroBarComponent'
import LoadingBox from '../component/common/LoadingBox'
import MessageBox from '../component/common/MessageBox'
import { getdentist } from '../redux/dentist/dentistSlice'
import { imgUrl } from '../redux/linkUrl'

import "./pageCSS.css"


const OurDentistPage = () => {


    const dispatch = useDispatch();
    const { isLoading, isError, dentists } = useSelector((state) => state.dentistDetail);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getdentist())



    }, [dispatch])



    return (
        <>

            <HeroBarComponent headingText="Our Team" linkRoute="/our-team" linkText="Our Team" />


            {/* <!-- Team Start --> */}
            <div className="container py-5 mb-5 overflow-hidden">

                <div className="row g-5">
                    <div className="col-lg-3 wow slideInUp px-5 px-md-3" data-wow-delay="0.1s">
                        <div className="section-title bg-light rounded h-100 p-2">
                            <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Team</h5>
                            <h2 className=" mb-4">Meet Our Certified & Experienced Team Members</h2>
                            <div className='text-center'>
                                <Link to="/appointment" className="btn btn-primary py-3 px-5">Appointment</Link>

                            </div>
                        </div>
                    </div>

                    {isLoading ? (
                        <LoadingBox />
                    ) : isError ? (
                        <MessageBox varient="danger">{isError}</MessageBox>
                    ) : (

                        <>


                            {
                                dentists && dentists.length > 0 && dentists.map((doctor, idx) => (
                                    <div className=" col-lg-3 px-5 px-md-3 wow slideInUp " data-wow-delay="0.3s" key={idx}>


                                        <div className="myhover team-item  bg-light h-100">
                                            <div className="position-relative rounded-top" style={{ zIndex: "1" }}>
                                                {
                                                    doctor.doc_img ?
                                                        <img className="img-fluid rounded-top w-100  p-5 pb-0" src={`${imgUrl}${doctor.doc_img}`} alt="Our Doctor" />
                                                        :
                                                        <img className="img-fluid rounded-top w-100  p-5 pb-0" src={"assets/img/ava1.png"} alt="Our Doctor" />


                                                }

                                            </div>
                                            <div className="position-relative text-center rounded-bottom p-4">
                                                <h4 className="mb-2">{doctor.doc_name ? doctor.doc_name : ""}</h4>
                                                <p className="text-primary mb-0">{doctor.position ? doctor.position : ""}</p>

                                                {
                                                    doctor.nmc &&
                                                    <p className="text-primary mb-0"> {doctor.nmc ? doctor.nmc : ""}</p>

                                                }
                                            </div>

                                        </div>
                                    </div>


                                ))

                            }



                        </>
                    )
                    }




                </div>

            </div>
            {/* <!-- Team End --> */}







        </>
    )
}

export default OurDentistPage