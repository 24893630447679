import React from 'react'
import { Link } from 'react-router-dom'

const BannerComponent = ({ setting }) => {
    return (
        <>

            <div className="container-fluid banner mb-5 d-flex">
                <div className="container">
                    <div className="row gx-0 justify-content-center ">
                        <div className="col-lg-4 wow zoomIn " data-wow-delay="2s">
                            <div className="bg-primary d-flex flex-column p-5" style={{ height: "300px" }}>
                                <h3 className="text-white mb-3">Opening Hours</h3>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Sun - Fri</h6>
                                    <p className="mb-0"> 9:00am - 6:00pm</p>
                                </div>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Saturday</h6>
                                    <p className="mb-0">9:00am - 2:00pm</p>
                                </div>
                                {/* <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Sunday</h6>
                                    <p className="mb-0"> 8:00am - 5:00pm</p>
                                </div> */}
                                <Link className="btn btn-light" to="/appointment">Appointment</Link>
                            </div>
                        </div>

                        <div className="col-lg-4 wow zoomIn" data-wow-delay="1.20s">
                            <div className="bg-secondary d-flex flex-column p-5" style={{ height: "300px" }}>
                                <h3 className="text-white mb-3">Make Appointment</h3>
                                <h5 className="text-white">Contact Us For Appointment </h5>
                                <h2 className="text-white mb-0">{setting && setting.site_phone ? setting.site_phone : ""}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerComponent