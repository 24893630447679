import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LoadingBox from '../component/common/LoadingBox'

import AppointmentHomeComponent from '../component/HomeComponent/AppointmentHomeComponent'
import BannerComponent from '../component/HomeComponent/BannerComponent'
import CarouselComponent from '../component/HomeComponent/CarouselComponent'
import OfferComponent from '../component/HomeComponent/OfferComponent'
import PriceHomeComponent from '../component/HomeComponent/PriceHomeComponent'
import ServiceHomeComponent from '../component/HomeComponent/ServiceHomeComponent'
import ShortAbout from '../component/HomeComponent/ShortAbout'
import { getSiteSetting } from '../redux/setting/settingSlice'

const HomePage = () => {



    const dispatch = useDispatch();
    const { isLoading, isError, siteSetting } = useSelector((state) => state.siteSetting);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getSiteSetting())



    }, [dispatch])

    // if (isLoading) {
    //     return <p>....</p>
    // }


    return (
        <>


            <CarouselComponent />
            <BannerComponent setting={siteSetting} />
            <ShortAbout />
            <AppointmentHomeComponent />

            <ServiceHomeComponent setting={siteSetting} />
            <OfferComponent />
            <PriceHomeComponent setting={siteSetting} />




        </>
    )
}

export default HomePage