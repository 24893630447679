
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import bannerService from "./bannerService"


const initialState = {
    banners: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
    imgurl: ""
}

export const getBanners = createAsyncThunk("banners", async (thunkAPI) => {
    try {
        return await bannerService.banners();
    } catch (err) {
        const message = (
            err.response && err.response.data && err.response.data.message
        ) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const bannerSlice = createSlice({
    name: "banners",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getBanners.pending, (state) => {
                    state.isLoading = true
                }

            )
            .addCase(
                getBanners.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.banners = action.payload.sliders;
                    state.imgurl = action.payload.imgurl
                }
            )
            .addCase(
                getBanners.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.message = action.payload;
                    state.banners = null
                }
            )
    }
})

export const { reset } = bannerSlice.actions;
export default bannerSlice.reducer;