import React from 'react'
import AppointmentHomeComponent from '../component/HomeComponent/AppointmentHomeComponent'

const AppointmentPage = () => {
    return (
        <div>
            <AppointmentHomeComponent />
        </div>
    )
}

export default AppointmentPage