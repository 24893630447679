import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MessageBox from '../common/MessageBox';

import { useDispatch, useSelector } from 'react-redux';
import { imgUrl } from '../../redux/linkUrl';
import { getServices } from '../../redux/service/serviceDataSlice';
import { getPriceDetail } from '../../redux/priceSectionDetail/priceDetailSlice';
import { Link } from 'react-router-dom';


const PriceHomeComponent = ({ setting }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };





    const dispatch = useDispatch();
    const { isLoading, isError, services } = useSelector((state) => state.services);
    const { isLoading: loading, isError: error, priceDetail } = useSelector((state) => state.priceDetail);


    useEffect(() => {

        dispatch(getServices())



    }, [dispatch])



    useEffect(() => {
        setTimeout(() => {
            dispatch(getPriceDetail())
        }, 2000)


    }, [dispatch])







    return (
        <>
            <div className="container-fluid py-5 wow fadeInUp mb-4" data-wow-delay="0.1s" >
                <div className="container">
                    <div className="row g-5">
                        {
                            loading ? (
                                <></>
                            ) : (
                                <>
                                    {
                                        priceDetail && priceDetail != null &&
                                        <div className="col-lg-5">
                                            <div className="section-title mb-4">
                                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Pricing Plan</h5>
                                                <h1 className="display-5 mb-0">{priceDetail.price_title ? priceDetail.price_title : ""}</h1>
                                            </div>
                                            <p className="mb-4" dangerouslySetInnerHTML={{ __html: priceDetail.price_des ? priceDetail.price_des : "" }} ></p>
                                            <h5 className="text-uppercase text-primary wow fadeInUp" data-wow-delay="0.3s">Call for Appointment</h5>
                                            <h1 className="wow fadeInUp" data-wow-delay="0.6s">{setting && setting.site_phone ? setting.site_phone : ""}</h1>
                                        </div>
                                    }
                                </>
                            )
                        }



                        <div className="col-lg-7 ">
                            <div className=" price-carousel wow zoomIn" >
                                {
                                    isLoading ? (
                                        // <LoadingBox />
                                        <>.</>
                                    ) : error ? (
                                        <MessageBox varient="danger">{error}</MessageBox>
                                    ) : (
                                        <Slider {...settings}>
                                            {
                                                services && services.slice(0, 3).map((s, idx) => (

                                                    <div className="price-item pb-4 pe-4" key={idx}>
                                                        <div className="position-relative">
                                                            <img className="img-fluid rounded-top" src={`${imgUrl}${s.service_img}`} alt="" />
                                                            {
                                                                s.service_price &&
                                                                <div className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle" style={{ zIndex: "2" }}>
                                                                    <h2 className="text-primary m-0">{s.service_price}</h2>
                                                                </div>

                                                            }

                                                        </div>
                                                        <div className="position-relative text-center bg-light border-bottom border-primary py-5 p-4">
                                                            <h4>{s.service_name}</h4>
                                                            <hr className="text-primary w-50 mx-auto mt-0" />
                                                            <div className="d-flex justify-content-between mb-3"><span>Modern Equipment</span><i className="fa fa-check text-primary pt-1"></i></div>
                                                            <div className="d-flex justify-content-between mb-3"><span>Professional Dentist</span><i className="fa fa-check text-primary pt-1"></i></div>
                                                            <Link to="appointment" className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle">Appointment</Link>
                                                        </div>
                                                    </div>




                                                ))
                                            }


                                        </Slider>

                                    )
                                }

                            </div>
                        </div>




                    </div>
                </div>
            </div>

        </>
    )
}

export default PriceHomeComponent